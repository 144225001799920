.jodit-workplace {
  height: unset !important;
}
.jodit-container.jodit.jodit-wysiwyg_mode {
  height: unset !important;
}
.jodit-workplace > .jodit-wysiwyg {
  overflow: unset;
}
.jodit-status-bar {
  display: none;
}

.jodit-workplace + .jodit-status-bar:not(:empty) {
  display: none;
}

.jodit-workplace + .jodit-status-bar:not(:empty) {
  display: none;
}

.jodit-editor__resize {
  display: none;
}

/*Editor Custom CSS*/
.detailprofile-outer .blog-content image-para {
  overflow: auto;
}
