.main-blog-detail-page {
  max-width: 1400px;
  background: whitesmoke;
  padding: 30px 10px 10px;
  border-radius: 10px;
  margin: 30px auto 30px;
}
.blog-modal-content {
  padding: 0 12px;
}

.save-btn-dashboard.save-btn {
  display: inline-block;
  padding: 14px 20px;
  background: #0d66b1 !important;
  color: white !important;
}
.save-btn-dashboard.save-btn:hover {
  background: #054e8b !important;
}
button.save-btn.save-btn-dashboard.save-draft-button {
  background: #d46363 !important;
}
button.save-btn.save-btn-dashboard.save-draft-button:hover {
  background: #aa4b4b !important;
}
button.save-btn.save-btn-dashboard.preview-btn-blog {
  background: #848484 !important;
}
button.save-btn.save-btn-dashboard.preview-btn-blog:hover {
  background: #5f5f5f !important;
}
