/* .container {
  height: 80%;
} */

.faq_container {
  background: #e4f4ff;
  padding: 50px 0;
}

.faq_content h2 {
  font-size: 28px;
  font-weight: 500;
  line-height: 45px;
}
.faq_content p {
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
.pading_unset {
  padding-top: unset !important;
}
.faq_content {
  margin-top: 20px;
}

.faq_accordion {
  margin: 50px 0 30px;
  /* overflow: auto;
  height: 420px; */
}
.next_btns {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.accordion_heading p {
  font-size: 14px;
  /* margin-bottom: 15px; */
}

.accordion_summary_text p {
  font-weight: 500;
  font-size: 16px;
}

/* --------------------------------------------- */

/* .accordion_summary_text {
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis !important;
} */

.accordion_summary_fixed_height {
  height: 60px;
  min-height: 60px;
}

.accordion_heading {
  margin-bottom: 15px;
}

.accordion_summary_text:focus {
  border-color: #86b7fe;
  box-shadow: 0 0 0 0.25rem #0d6efd40;
  outline: 0;
  z-index: 3;
}

.accordion_summary_text:not(.Mui-expanded) {
  background-color: #f0f0f0;
}

.accordion_summary_text:focus:not(:focus-visible) {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  min-height: 64px;
  color: #fff;

  background-color: #0170c2;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  max-height: 65px;
}

@media (max-width: 767px) {
  .faq_img img {
    width: 100%;
  }
}

.faq_pagination {
  padding-bottom: 40px;
}

@media (max-width: 600px) {
  .faq_pagination {
    padding-bottom: 60px;
  }
}
@media (max-width: 380px) {
  .faq_pagination {
    padding-bottom: 80px;
  }
}

.custom-faq-accordion .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root {
  height: 70px;
}

/*28/11/2024*/
