.student-banner .carousel-inner .carousel-item .carousel-caption {
  text-align: left;
  bottom: unset;
  top: 15%;
}
.student-banner .carousel-inner .carousel-item .carousel-caption p {
  width: 700px;
  text-align: justify;
}
.student-banner .carousel-inner .carousel-item .carousel-caption h5 {
  font-weight: 500;
  font-size: 34px;
  margin-bottom: 10px;
}

.blog-heading {
  color: #fff;
}

.student-banner .carousel-inner .carousel-item .carousel-caption button {
  padding: 6px 42px;
  border-radius: 20px;
  border: none;
  background-color: #ffffff21;
  color: #fff;
  font-size: 16px;
  margin-bottom: 25px;
}
.student-banner .carousel-indicators {
  /* justify-content: start; */
  margin-bottom: 2px;
}
.student-banner .carousel-indicators [data-bs-target] {
  height: 10px;
  width: 10px;
  border-radius: 50px;
  border: unset;
  background-color: #fff !important;
}
.student-banner .carousel-control-prev {
  background: #ffffff21;
  height: 45px;
  width: 45px;
  border-radius: 30px;
  opacity: 0.9;
  z-index: 9;
  left: 1%;
  top: 42%;
}
.student-banner .carousel-control-next {
  background: #ffffff21;
  height: 45px;
  width: 45px;
  border-radius: 30px;
  opacity: 0.9;
  z-index: 9;
  right: 1%;
  top: 42%;
}
.student-banner .carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 1.2rem;
}
.student-profile {
  display: flex;
  align-items: center;
  gap: 15px;
  /* margin-top: 30px; */
}

.student-profile img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1.5px solid #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  object-fit: cover;
}

.student-profile h6 {
  margin-bottom: 5px;
  font-size: 14px;
}
.student-profile p {
  margin-bottom: 0px;
  font-size: 12px;
}

.carousel-image {
  width: 100%;
  flex: 0 0 100%;
  object-fit: cover;
}
/*28/11/2024*/
.student-banner .carousel-inner {
  background-color: #125597;
  /* height: 42vh; */
  position: relative;
}
.banner-img {
  position: absolute;
  right: 10%;
  bottom: 0px;
}
.student-banner .carousel-inner .carousel-item {
  height: 100%;
}
.student-banner .carousel-inner .carousel-item.active {
  height: 100%;
}

@media screen and (max-width: 1366px) {
  .student-banner .carousel-inner .carousel-item .carousel-caption p {
    width: 550px;
  }
  .student-banner .carousel-indicators {
    margin-bottom: 2px;
  }

  .student-banner .carousel-inner .carousel-item .carousel-caption button {
    margin-bottom: 15px;
  }
  .banner-img {
    width: 100%;
    max-width: 350px;
  }
}
@media screen and (max-width: 1024px) {
  .student-banner .carousel-inner .carousel-item .carousel-caption p {
    font-size: 12px;
    width: 400px;
  }
  .student-banner .carousel-inner .carousel-item .carousel-caption h5 {
    font-size: 16px;
  }

  .student-banner .carousel-inner .carousel-item .carousel-caption button {
    margin-bottom: 5px;
    font-size: 14px;
  }
  .student-profile {
    margin-top: 10px;
  }
  .student-banner .carousel-inner {
    /* height: 25vh; */
  }
  .blog-text-content {
    font-size: 16px;
  }
}
@media screen and (max-width: 820px) {
  .student-profile img {
    height: 100% !important;
  }

  .student-banner .carousel-inner .carousel-item .carousel-caption {
    left: 8%;
  }
  .student-banner .carousel-inner .carousel-item .carousel-caption p {
    width: 330px;
  }
  .student-banner .carousel-indicators {
    /* margin-left: 8%; */
  }
  .banner-img {
    width: 300px;
  }
}
@media screen and (max-width: 576px) {
  .student-banner .carousel-indicators {
    /* margin-left: 12%; */
  }
  .banner-img {
    width: 150px;
    right: 0%;
  }
  .student-banner .carousel-inner .carousel-item .carousel-caption {
    left: 12%;
  }
  .student-banner .carousel-inner {
    /* height: 30vh; */
  }
  .student-banner .carousel-inner .carousel-item .carousel-caption {
    top: 8%;
  }
  .student-banner .carousel-inner .carousel-item .carousel-caption h5 {
    font-size: 14px;
  }
}

.blog-text-content {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  width: 50%;
  max-width: 800px;
}

/* 2/3/2025 */
.bannerImageParent {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.bannerImageParent .bannerImage {
  width: 100%;
  max-width: 400px;
}
.bannerContentTop {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: start;
  padding-top: 20px;
}
.bannerContentBottom {
  height: 100px;
  width: 100%;
}
.caroselRow {
  /* padding-inline: 60px; */
}
.blog-text-content {
  color: #fff;
}
.student-profile {
  color: #fff;
}
.blog-heading {
  display: -webkit-box;
  width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.blog-text-content {
  display: -webkit-box;
  width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
