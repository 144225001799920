.blog-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0px;
  flex-wrap: wrap;
  gap: 15px;
}
.blog-head h3 {
  font-size: 26px;
}
.blog-head input {
  border: 1px solid #dfdfdf;
  padding: 12px;
  border-radius: 10px;
  /* width: 320px; */
}
.blog-head input:focus-visible {
  outline: none;
}
.blog-input {
  position: relative;
}
.blog-input span {
  position: absolute;
  right: 10px;
  top: 10px;
}

input[type="search"]::-webkit-search-cancel-button {
  cursor: pointer;
  color: red;
}

.blog-card-outer .card {
  margin-bottom: 40px;
}
.blog-card-outer .card .card-body .card-title {
  font-size: 22px;
  font-weight: 500;
  color: #000;
}

.blog-card-outer .card .card-body .card-text {
  color: #575757;
  font-size: 16px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.blog-card-outer .card .card-body a {
  border: 1px solid #000;
  padding: 10px 20px;
  color: #000;
  font-weight: 500;
  text-align: center;
}

.custom-blog-card .card img {
  /* max-width: 220px !important; */
  width: 100% !important;
  margin: auto;
  /* padding: 15px; */
  height: 270px !important;
  object-fit: cover;
  object-position: top;
  height: 100%;
}

.custom-blog-card .card .card-body {
  height: 8rem !important;
  /* min-height: 130px; */
  height: 100%;
}
.custom-blog-card .card .card-footer {
  background: unset;
  border: unset;
  padding: 15px 0;
}

.blog-card-outer .card .card-body .card-title {
  /* color: #575757; */
  /* font-size: 16px; */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  cursor: pointer;
}

.blog-card-outer .card .card-body .card-title:hover {
  color: #125597;
}

.oops-div {
  min-height: 30vh;
}
.oops-div img {
  height: 7rem;
}
.pagination-blog {
  margin-top: 0rem !important;
}
