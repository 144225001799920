.MuiBox-root.css-1dyuqpd {
  display: flex;
}

.SpeedContainer {
  position: fixed;
  bottom: 60px;
  right: 50px;
  z-index: 99;
}
