.Card {
  border: 1px solid #ddd;
  border-radius: 10px !important;
  padding: 16px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  background-color: #fff;
}

.rm-logo {
  width: 120px;
}
.img-container {
  margin-top: 10px;
}
.form-check.form-switch label {
  margin-right: 0;
}
@media (max-width: 767px) {
  .logo_cart_flex {
    flex-direction: column;
    align-items: baseline;
  }
  .logo_cart_flex .img-container {
    margin-top: unset;
  }
}
