.blog-card-list {
  min-height: 400px;
}

.clear-button:hover {
  cursor: pointer;
}
.blog-container {
  padding-bottom: 40px;
}

@media (max-width: 600px) {
  .blog-container {
    padding-bottom: 60px;
  }
}
@media (max-width: 380px) {
  .blog-container {
    padding-bottom: 80px;
  }
}
