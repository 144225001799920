.blog-head {
  margin: 20px 0px;
}
.blog-head h3 {
  font-size: 26px;
}
.details-profile {
  margin-top: 0px;
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 20px 0px;
}

.details-profile img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  /* border: 1.5px solid #b6b6b6; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  object-fit: cover;
}

.details-profile h6 {
  margin-bottom: 5px;
  font-size: 16px;
  color: #0c0c0c;
  font-weight: 500;
}
.details-profile p {
  margin-bottom: 0px;
  font-size: 12px;
  color: #716f6f;
  font-weight: 500;
}
.blog-image {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 30px 0px;
}
.blog-image button {
  width: 100%;
  background-color: #659feb;
  padding: 8px 10px;
  border-radius: 20px;
  border: none;
  max-width: 150px;
  color: #fff;
}
.blog-image img {
  height: 100%;
  /* max-height: 350px; */
  object-fit: cover;
}
.blog-content {
  margin-top: 15px;
}
.blog-content h4 {
  font-size: 24px;
  font-weight: 500;
  color: #000;
}
.blog-content p {
  color: #959595;
  font-size: 18px;
  /* font-family: source-serif-pro, Georgia, Cambria, "Times New Roman", Times,
    serif; */
  font-style: normal;
}

.blog-content-image {
  max-height: 500px !important;
  width: 100% !important;
  /* object-fit: cover !important; */
  color: #fff !important;
  margin: 20px 0;
}

.footer-sec {
  background: linear-gradient(45deg, #0a55a0, #1298e8, #0b57a0);
  padding: 3rem;
  width: 100% !important;
  min-height: 160px;
  height: auto;
  /* position: fixed;
  z-index: 999;
  bottom: 0px; */
  position: absolute;
  bottom: unset !important;
}
/*28/11/2024*/
.blog-title h1 {
  font-size: 36px;
  color: #0c0c0c;
  letter-spacing: -0.014em;
  margin-bottom: 24px;
  /* font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif; */
  font-weight: 700;
}

.blog-content h1 {
  font-size: 32px;
  color: #0c0c0c;
}

.blog-content h2 {
  font-size: 28px;
  color: #0c0c0c;
  letter-spacing: -0.03em;
  /* font-family: gt-super, Georgia, Cambria, "Times New Roman", Times, serif; */
}

/* .blog-content h1 h2 h3 h4 h5 h6 {
  color: #0c0c0c;
  font-family: gt-super, Georgia, Cambria, "Times New Roman", Times, serif;
} */

.blog-content h1,
.blog-content h2,
.blog-content h3,
.blog-content h4,
.blog-content h5,
.blog-content h6 {
  color: #0c0c0c;
  /* font-family: gt-super, Georgia, Cambria, "Times New Roman", Times, serif; */
}

.details-profile h6 {
  color: #242424;
  /* font-family: gt-super, Georgia, Cambria, "Times New Roman", Times, serif; */
  font-size: 16px;
}

.detailprofile-outer {
  min-height: calc(100vh - 90px);
  height: 100%;
}
ul.navbar-nav.ms-auto {
  flex-wrap: wrap;
}

/* 2/3/2025 */
.backToHome {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 1px solid rgb(197, 197, 197);
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
}
