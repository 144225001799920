/* Container styling */

.footer-app-icon {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.footer-app-icon img {
  height: 50px;
  width: 165px;
  margin: 10px 10px 0 0;
}
.scale-up-animation:hover {
  transform: scale(1.1);
}

.connect-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #f0f0f0; */
  overflow: hidden;
  margin-top: 60px;
  padding-bottom: 40px;
}

.icon-image-logo {
  width: 48px;
  border-radius: 10px;
  margin-bottom: 12px;
}

.blurred-background {
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.6);
  padding: 50px;
  width: 60%;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.connect-title {
  margin-bottom: 50px;
  font-weight: 400 !important;
  color: #333;
  padding-bottom: 20px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
}
.connect-page-title {
  padding-bottom: 50px;
}

.social-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150px;
  border-radius: 20px !important;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0s ease-in-out;
  cursor: pointer;
}

.social-card:hover {
  transform: scale(1.1);
}

.social-icon {
  font-size: 64px !important;
  margin-bottom: 10px;
}

.facebook-color {
  color: #3b5998;
}

.twitter-color {
  color: #1da1f2;
}

.google-color {
  color: #db4437;
}

.linkedin-color {
  color: #0077b5;
}

.youtube-color {
  color: #ff0000;
}

/* Social Text Styling */
.social-text {
  font-size: 20px;
  font-weight: 500;
  color: #333;
}

/* -----26-10-2024----- */

.cstm-social-box .MuiGrid-root.MuiGrid-container {
  width: unset !important;
  margin-left: unset !important;
  gap: 18px !important;
}

.cstm-social-box .css-1xnaaoc-MuiGrid-root > .MuiGrid-item {
  padding-left: unset !important;
}

.cstm-social-box .css-1xnaaoc-MuiGrid-root > .MuiGrid-item {
  padding-top: unset !important;
}

.cstm-social-box .css-ag7rrr-MuiTypography-root {
  font-size: 14px !important;
  font-weight: 600;
}

.cstm-social-box .css-46bh2p-MuiCardContent-root:last-child {
  padding-bottom: unset;
  padding: 0px;
}

.cstm-social-box .social-card {
  padding: 12px 5px;
  border-radius: 10px !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.cstm-heading-inner {
  font-size: 34px !important;
  font-weight: 600 !important;
  padding-bottom: 30px !important;
}
.cstm-social-box .connect-title-1 {
  font-size: 34px;
  margin-top: 30px;
  margin-bottom: 12px;
  font-weight: 600 !important;
}
.cstm-social-box .blurred-background {
  padding: 15px !important;
}
.cstm-social-box .footer-app-icon {
  height: unset !important;
  padding-bottom: 15px !important;
}

/*write css 4th nov 2024*/
.social-media-main-box {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.blurred-background {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.connet-with-wrapper {
  width: 100%;
  max-width: 500px;
}
.social-media-icon-box {
  width: 150px;
  height: 100%;
}
.social-icon-box {
  width: 64px;
  height: 64px;
}
.social-icon-box > .icon-image-logo,
.social-icon-box > svg {
  width: 100%;
  height: 100%;
}

h4.connect-heading {
  font-weight: 500;
}
h4.connect-heading {
  font-weight: 600;
  font-size: 26px;
  margin-bottom: 30px;
}
.bottom-conatiner {
  margin-top: 50px;
  border-top: 1px solid gray;
  width: 95%;
}
.cstm-social-box .blurred-background {
  margin: 18px 0;
}

@media only screen and (max-width: 1024px) {
  .cstm-social-box .blurred-background {
    width: 90% !important;
  }
}
@media only screen and (max-width: 768px) {
  .cstm-social-box .MuiGrid-root.MuiGrid-container {
    gap: 25px !important;
    width: 70% !important;
    margin: 0 auto !important;
  }
  .cstm-social-box .social-card {
    min-height: 130px !important;
  }
  .cstm-social-box {
    height: unset !important;
    padding: 25px 0px;
  }
  .cstm-heading-inner {
    font-size: 28px !important;
    padding-top: 10px;
    padding-bottom: 20px !important;
    font-weight: 700 !important;
  }
  .cstm-social-box .blurred-background {
    padding: 10px !important;
    width: 90%;
  }
}

@media only screen and (max-width: 550px) {
  .cstm-social-box .MuiGrid-root.MuiGrid-container {
    width: 100% !important;
  }
}
