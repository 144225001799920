body {
  background: #fff;
}

#loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.spinner {
  border-radius: 50%;
  box-sizing: border-box;
  border: 5px solid #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.inner {
  border-top: 5px solid #0585ce;
  border-bottom: 5px solid #0585ce;
  width: 70px;
  height: 70px;
  margin: -35px;
  -webkit-animation: inner-spin 1.5s linear infinite;
  animation: inner-spin 1.5s linear infinite;
}

.outer {
  border-right: 5px solid #0585ce;
  border-left: 5px solid #0585ce;
  width: 90px;
  height: 90px;
  margin: -45px;
  -webkit-animation: outer-spin 1s linear infinite;
  animation: outer-spin 1s linear infinite;
}

.eye {
  width: 50px;
  height: 50px;
  border: none;
  background-color: #0585ce;
  animation: eye-flash 1s infinite;
}
.logo {
  font-family: "Baskervville", "serif";
  font-weight: 600;
  font-size: 1.5rem;
  position: absolute;
  width: 50px;
  height: 50px;
  z-index: 9;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: rm 1s infinite;
}

@-webkit-keyframes inner-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes inner-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes outer-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate-(180deg);
  }
}

@keyframes outer-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-180deg);
  }
}

@keyframes eye-flash {
  0% {
    background-color: #0585ce;
  }
  50% {
    background-color: #fff;
  }
  100% {
    background-color: #0585ce;
  }
}
@keyframes rm {
  0% {
    color: #fff;
  }
  50% {
    color: #0585ce;
  }
  100% {
    color: #fff;
  }
}

.loader-logo {
  height: 50px;
  width: 50px;
}

.z-index-cstm {
  z-index: 999999 !important;
}
