* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
}

p {
  font-size: 18px;
}

header {
  background: #fff;
  box-shadow: 0 3px 15px #a4a4a421;
  padding: 0 20px;
  position: sticky;
  top: 0;
  z-index: 99;
  width: 100%;
}

.no-drop {
  cursor: no-drop !important;
}

.p-none {
  pointer-events: none !important;
}

.upgrade-subscription-btn {
  background: #1298e8 !important;
  width: 100% !important;
  border: none !important;
  outline: none !important;
  text-transform: uppercase !important;
}

.upgrade-plan-modal {
  margin-right: auto;
  /* margin-left: -10%; */
}

span.nowrap {
  white-space: nowrap;
  font-size: 10px;
}
/* .upgrade-plan-modal .modal-content {
  width: 900px !important;
  padding-right: 0rem !important;
} */

header .navbar-toggler {
  border: none;
}

header .navbar-toggler:focus {
  box-shadow: none;
}

#mynavbar li.nav-item {
  margin: 0 2rem;
}

#mynavbar li.nav-item:first-child,
#mynavbar li.nav-item:last-child {
}

#mynavbar .nav-link {
  color: #000;
}

#mynavbar .nav-link:hover {
  color: #0b4f9a;
  transition: all 0.5s;
}

#mynavbar .nav-link:after {
  height: 2px;
  content: "";
  height: 2px;
  width: 0;
  display: block;
  transition: all 0.5s;
  margin-top: 4px;
}

#mynavbar .nav-link:hover:after {
  height: 2px;
  content: "";
  height: 2px;
  width: 0;
  display: block;
  transition: all 0.5s;
  margin-top: 4px;
  position: relative;
  top: 10px;
}

.banner-section {
  background: #f5fbff;
  margin-top: 6.6rem;
}

.banner-section .bannerHeading {
  line-height: 50px;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 2.7rem;
  color: #000;
  margin-bottom: 2.5rem;
}

.banner-section .bannerHeading span.textClr {
  color: #0b4f9a;
}

.banner-section .bannerTitle {
  padding-top: 4rem;
  padding-right: 3rem;
}

.banner-section .bannerTitle p {
  line-height: 26px;
  color: #747474;
  text-align: justify;
}

.more-btn {
  background: linear-gradient(to top, #0b4f9a, #0585ce);
  color: #fff;
  border: 0;
  border-radius: 30px;
  padding: 12px 3rem;
  margin-top: 2rem;
  box-shadow: 0px 6px 25px #0b4f9a40;
  transition: all 0.5s;
}

.more-btn:hover {
  color: #0b4f9a;
  border: 1px solid #0b4f9a;
  background: #fff;
}

.testimonial-sec {
  padding: 5rem 0;
}

.sectionHead {
  text-align: center;
  position: relative;
  margin-bottom: 4rem;
}

.main-content-gap {
  margin: 8rem 0rem 0rem 0rem;
  padding-bottom: 180px;
}

.main-content-gap .topHeading {
  font-size: 2.5rem;
  font-weight: 600;
  color: #424242;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.topHeadingBack {
  position: absolute;
  top: 22px;
  font-size: 7rem;
  left: 50%;
  transform: translateX(-50%);
  color: #b1b1b1;
  opacity: 0.1;
}

.testimonials {
  position: relative;
}

.testimonials .bgPattern {
  position: absolute;
  right: -300px;
  top: -250px;
  z-index: 0;
}

.testimonial-sec .sectionHead p {
  max-width: 490px;
  margin: auto;
  line-height: 26px;
  color: #747474;
}

.testimonials .item {
  box-shadow: 0px 5px 30px gainsboro;
  padding: 2rem;
  border-radius: 14px;
  text-align: center;
  min-height: 516px;
  background: #fff;
}

.testimonials .item .customerPic {
  height: 60px;
  width: 60px;
  border-radius: 100%;
  overflow: hidden;
  border: 2px solid #fff;
  box-shadow: 0px 7px 20px #eeeeeee0;
  margin: 1rem auto;
}

.testimonials .item .itemCaption {
  text-align: justify;
  padding: 2rem 0 1rem 0;
  color: #000;
}

.testimonials .item .itemCaption p:after {
  content: "";
  height: 2px;
  width: 74px;
  background: #0b4f9a;
  display: block;
  margin: 20px auto 0 auto;
}

.owl-nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
}

button.owl-prev {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border: 1px solid gainsboro !important;
  box-shadow: 0px 4px 20px gainsboro;
  border-radius: 100%;
  margin-right: 22px;
  font-size: 34px !important;
}

button.owl-next {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border: 1px solid gainsboro !important;
  box-shadow: 0px 4px 20px gainsboro;
  border-radius: 100%;
  font-size: 34px !important;
}

.owl-dots {
  position: absolute;
  left: 50%;
  bottom: 0px;
  transform: translateX(-50%);
}

button.owl-dot.active {
  background: #0b4f9a !important;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  box-shadow: 0px 0px 20px #0b4f9a;
}

button.owl-dot {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background: #cdcdcd !important;
  margin: 5px;
}

.information-sec {
  padding: 2rem 0 3rem 0;
}

.information-sec .infoCard {
  box-shadow: 0px 8px 30px #dcdcdc91;
  padding: 2rem 2rem 1rem;
  min-height: 266px;
}

.information-sec .infoCard > h5 {
  color: #0b4f9a;
  margin-bottom: 1.5rem;
}

.information-sec .infoCard > p {
  color: #747474;
}

.information-sec .infoCard > p > b {
  color: #000;
}

.information-sec .infoCard > a {
  color: #0b4f9a;
  display: inline-block;
  padding: 0;
  margin-right: 38px;
}

.information-sec .info-button {
  text-align: end;
  margin-top: 2rem;
}

.viewAll-btn {
  border: 1px solid gainsboro;
  border-radius: 30px;
  padding: 10px 2rem;
  box-shadow: 0px 5px 25px #dcdcdc80;
}

.viewAll-btn:hover {
  background: #0b4f9a;
  color: #fff;
  border: 1px solid #0b4f9a;
  box-shadow: 0px 5px 25px #0b4f9a63;
}

.contact-section {
  padding-top: 5rem;
  padding-bottom: 2.5rem;
}

.contact-topmarzin {
  padding-top: 1rem;
}

.contact-section .innerContact {
  background-image: url(../images/contactBg.png);
  background-size: 100%;
  position: relative;
  min-height: 410px;
  padding-top: 2rem;
}

.contact-section .contactForm-sec {
  background: #fff;
  width: 100%;
  margin: auto;
  padding: 2rem 4rem;
  box-shadow: 0px 16px 30px #87878745;
  position: relative;
  max-width: 90%;
  top: -100px;
}

.innerContact .sectionHead .gpsIcon {
  position: absolute;
  right: 235px;
  bottom: 6px;
  max-width: 34px;
}

.contactForm-sec .contactRight > h3,
.contactForm-sec .contactLeft > h3 {
  color: #0b4f9a;
}

.contactForm-sec .contactRight .contactInfo,
.contactForm-sec .contactLeft .contact-form {
  margin-top: 4rem;
}

.contactForm-sec .contactRight .contactInfo .infoDiv {
  display: flex;
  color: #000;
  align-items: center;
}

.contactForm-sec .contactRight .contactInfo .infoDiv p {
  margin: 0 0 0 2.5rem;
}

.contactForm-sec .contactRight .contactInfo .socialMedia {
  display: flex;
  align-items: center;
  margin-top: 3rem;
}

.contactForm-sec .contactRight .contactInfo .socialMedia a {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2rem;
}

.contactForm-sec .contactRight .contactInfo .socialMedia a:nth-child(1) {
  margin-left: 0;
}

.contact-form .inputBox input {
  height: 60px;
  box-shadow: 0px 10px 30px #d2d2d254;
  padding-right: 60px;
}

.form-control:focus {
  color: #0c4f98;
  background-color: #fff;
  border-color: #0c4f98;
  box-shadow: none;
}

.contact-form .inputBox .inputIcon {
  position: absolute;
  right: 16px;
  bottom: 17px;
}

.contact-form .inputBox .form-label {
  position: absolute;
  background: #fff;
  left: 14px;
  top: -10px;
  padding: 0 5px;
  color: #747474;
}

.contact-form .inputContent {
  margin-bottom: 2rem;
}

.contact-form .inputBox textarea {
  height: 86px;
  padding-top: 26px;
  padding-right: 86px;
}

.contact-form .inputBox .sendBtn {
  border: none;
  background: none;
  border-radius: 100%;
  position: absolute;
  right: 16px;
  top: 12px;
}

.innerWrapper {
  background-image: url(../images/LoginBg.png);
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 7rem;
}

.topLogo > img {
  max-width: 24rem;
  min-width: 15rem;
}

.topLogo {
  padding-top: 2rem;
  margin-bottom: 2rem;
  margin-left: -17px !important;
}

.formLeft .formTitle h2 {
  font-size: 3.7rem;
  font-weight: 700;
  color: #000;
  max-width: 437px;
  margin-bottom: 2rem;
}

.formLeft .txtForm {
  font-weight: 600;
  color: #0453a0;
}

.formLeft .imgBox {
  position: relative;
  min-height: 300px;
}

.formLeft .imgBox img {
  /* position: absolute; */
  right: 130px;
  top: -68px;
}

.formRight {
  padding-left: 2rem;
  margin-bottom: 8rem;
}

.registration .formRight .formHead {
  margin-bottom: 4rem;
  margin-top: 0rem;
}

.formRight .formHead {
  margin-bottom: 4rem;
  margin-top: 8rem;
}

.formRight .formHead > h3 {
  font-size: 3rem;
  font-weight: 500;
  color: #0453a0;
  margin-bottom: 1rem;
}

.formRight .formHead > p {
  color: #747474;
}

.formSection .inputBox .form-label {
  position: absolute;
  background: #ffffff;
  left: 14px;
  top: -10px;
  padding: 0 5px;
  color: #747474;
}

.formSection .inputBox .inputIcon {
  position: absolute;
  right: 16px;
  bottom: 17px;
}

.award-botom {
  margin-bottom: 110px;
}

.formSection .inputBox input {
  height: 60px;
  box-shadow: 0px 10px 30px #d2d2d254;
  padding-right: 60px;
}

.formSection .inputContent {
  margin-bottom: 3rem;
}

form .form-check-input [type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-bottom: 5px;
}

form .form-check-input:checked {
  background-color: #005385;
  border-color: #005385;
}

.formSection .rememberMe .form-check-label {
  color: #747474;
}

.formSection .forgotLink > a {
  color: #0b4f9a;
}

.formSection .btnCommon {
  width: 100%;
  background: linear-gradient(to top, #0b4f9a, #0585ce);
  color: #fff;
  border: 0;
  border-radius: 30px;
  padding: 15px 3rem;
  margin-top: 3rem;
  box-shadow: 0px 6px 25px #0b4f9a40;
  transition: all 0.5s;
  font-weight: 500;
  font-size: 20px;
}

.formSection .formFoot {
  padding-top: 2rem;
  display: none;
}

.formSection .formFoot > p {
  color: #747474;
}

.formSection .formFoot p:before {
  content: "";
  height: 2px;
  width: 34%;
  background: #d8d8d8;
  position: absolute;
  left: 0;
  top: 50%;
}

.formSection .formFoot p:after {
  content: "";
  height: 2px;
  width: 34%;
  background: #d8d8d8;
  position: absolute;
  right: 0;
  top: 50%;
}

.formSection .formFoot .btnLinks {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

/* =====Register Page CSS===== */

.registration .topLogo {
  padding-top: 2rem;
  margin-bottom: 2rem;
}

.registration .formRight .formHead {
  margin-bottom: 2rem;
}

.registration .formLeft {
  margin-top: 8rem;
}

.registerForm .inputContent {
  margin-bottom: 2rem;
}

.registerForm .btnCommon {
  margin-top: 1rem;
}

/* ====Forgot Password CSS===== */

.forgotPassForm .midLine {
  margin-bottom: 3rem;
}

.forgotPassForm .midLine p {
  color: #0b4f9a;
}

.forgotPassForm .midLine p:before {
  content: "";
  height: 2px;
  width: 43%;
  background: #d8d8d8;
  position: absolute;
  left: 0;
  top: 50%;
}

.srink-it {
  width: 350px !important;
}

.mod-center {
  align-content: center;
}

.fix-icon {
  margin-bottom: 25px !important;
}

.forgotPassForm .midLine p:after {
  content: "";
  height: 2px;
  width: 43%;
  background: #d8d8d8;
  position: absolute;
  right: 0;
  top: 50%;
}

.backLink {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0b4f9a;
}

.backLink > i {
  font-size: 24px;
}

.forgotpage .formLeft .imgBox img {
  position: absolute;
  right: 130px;
  top: -68px;
  width: 100%;
  max-width: 380px;
}

.forgotPassForm .btnCommon {
  margin-top: 2rem;
}

.pdf {
  text-decoration: none;
  height: 20px;
  width: 5px;
  margin-left: 0.5rem;
  margin-right: 0rem;
}

/* Home Style */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
}

p {
  font-size: 18px;
}

header {
  background: #fff;
  box-shadow: 0 3px 15px #a4a4a421;
  padding: 0 20px;
  position: sticky;
  top: 0;
  z-index: 999;
  width: 100%;
}

.logoContent {
  max-width: 7.5rem;
}

header .navbar-toggler {
  border: none;
}

header .navbar-toggler:focus {
  box-shadow: none;
}

#mynavbar li.nav-item {
  margin: 0 2rem;
}

#mynavbar li.nav-item:first-child,
#mynavbar li.nav-item:last-child {
  /* margin-left: 0; */
}

#mynavbar .active {
  color: #0b4f9a !important;
}

#mynavbar .active:after {
  content: "";
  height: 2px;
  width: 100%;
  background: #0b4f9a;
  display: block;
  margin-top: 5px;
}

#mynavbar .nav-link {
  color: #000;
}

.banner-section {
  background: #f5fbff;
  padding-top: 6.6rem;
  min-height: calc(100vh - 165px);
}

.bannerLeft {
  padding: 7rem;
  display: flex;
  align-items: center;
}

.bannerRight video {
  min-height: 71vh;
}

.banner-section .bannerHeading {
  line-height: 50px;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 2.7rem;
  color: #000;
  margin-bottom: 2.5rem;
}

.banner-section .bannerHeading span.textClr {
  color: #0b4f9a;
}

.banner-section .bannerTitle {
  padding-top: 4rem;
  padding-right: 3rem;
}

.banner-section .bannerTitle p {
  line-height: 26px;
  color: #747474;
  text-align: justify;
}

.more-btn {
  background: linear-gradient(to top, #0b4f9a, #0585ce);
  color: #fff;
  border: 0;
  border-radius: 30px;
  padding: 12px 3rem;
  margin-top: 2rem;
  box-shadow: 0px 6px 25px #0b4f9a40;
  transition: all 0.5s;
}

.more-btn:hover {
  color: #0b4f9a;
  border: 1px solid #0b4f9a;
  background: #fff;
}

.testimonial-sec {
  padding: 0rem 0 0 0;
}

.sectionHead {
  text-align: center;
  position: relative;
}

.testimonials {
  position: relative;
}

.testimonials .bgPattern {
  position: absolute;
  right: -300px;
  top: -178px;
  z-index: 0;
}

.testimonial-sec .sectionHead p {
  max-width: 490px;
  margin: auto;
  line-height: 26px;
  color: #747474;
}

.testimonials .item {
  box-shadow: 0px 5px 8px gainsboro;
  padding: 2rem;
  border-radius: 14px;
  text-align: center;
  background: #fff;
  margin: 50px 0;
  height: 48ex0px;
}

.testimonials .owl-carousel .owl-item img {
  width: auto;
  display: inline-block;
}

.testimonials .item .customerPic {
  height: 60px;
  width: 60px;
  border-radius: 100%;
  overflow: hidden;
  border: 2px solid #fff;
  box-shadow: 0px 7px 20px #eeeeeee0;
  margin: 1rem auto;
}

.testimonials .item .itemCaption {
  text-align: justify;
  padding: 2rem 0.5rem 1rem 0;
  color: #000;
  height: 20vh;
  overflow-y: auto;
}

.testimonials .item .itemCaption::-webkit-scrollbar {
  width: 0.3em;
}

.testimonials .item .itemCaption::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.testimonials .item .itemCaption::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 0px solid slategrey;
}

.testimonials .item .itemCaption p:after {
  content: "";
  height: 2px;
  width: 74px;
  background: #0b4f9a;
  display: block;
  margin: 20px auto 0 auto;
}

.owl-nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1rem;
}

button.owl-prev {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border: 1px solid gainsboro !important;
  box-shadow: 0px 4px 20px gainsboro;
  border-radius: 100%;
  margin-right: 22px;
  font-size: 34px !important;
}

button.owl-next {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border: 1px solid gainsboro !important;
  box-shadow: 0px 4px 20px gainsboro;
  border-radius: 100%;
  font-size: 34px !important;
}

.owl-dots {
  position: absolute;
  left: 50%;
  bottom: 0px;
  transform: translateX(-50%);
}

button.owl-dot.active {
  background: #0b4f9a !important;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  box-shadow: 0px 0px 20px #0b4f9a;
}

button.owl-dot {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background: #cdcdcd !important;
  margin: 5px;
}

.information-sec {
  padding: 6.6rem 0 0 0;
  margin-bottom: 3rem;
}

.info-section .infoCard {
  box-shadow: 0px 8px 30px #dcdcdc91;
  padding: 2rem 2rem 1rem;
  height: 100%;
  /* min-height: 290px; */
}

.info-section .infoCard > h5 {
  color: #0b4f9a;
  margin-bottom: 1.5rem;
}

.info-section .infoCard > p {
  color: #747474;
  word-wrap: break-word;
}

.info-section .infoCard > p > b {
  color: #000;
}

.info-section .infoCard > a {
  font-size: 2rem;
  color: #f83939;
}

.info-section .info-button {
  text-align: end;
  margin-top: 2rem;
}

.viewAll-btn {
  border: 1px solid gainsboro;
  border-radius: 30px;
  padding: 10px 2rem;
  box-shadow: 0px 5px 25px #dcdcdc80;
}

.viewAll-btn:hover {
  background: #0b4f9a;
  color: #fff;
  border: 1px solid #0b4f9a;
  box-shadow: 0px 5px 25px #0b4f9a63;
}

.contact-section {
  margin: 6rem 0rem 0;
  padding-bottom: 60px;
  padding-top: 0rem;
}

.contact-section .innerContact {
  background-image: url(../images/contactBg.png);
  /* background: linear-gradient( to bottom, #0B4F9A, #0b509a67), url(../images/contactBg.png); */
  background-size: 100%;
  position: relative;
  min-height: 360px;
  padding-top: 2rem;
}

.contact-section .contactForm-sec {
  background: #fff;
  width: 100%;
  margin: auto;
  padding: 2rem 4rem;
  box-shadow: 0px 16px 30px #87878745;
  position: relative;
  max-width: 90%;
  top: -95px;
}

.innerContact .sectionHead .gpsIcon {
  position: absolute;
  right: 235px;
  bottom: 6px;
  max-width: 34px;
}

.contactForm-sec .contactRight > h3,
.contactForm-sec .contactLeft > h3 {
  color: #0b4f9a;
}

.contactForm-sec .contactRight .contactInfo,
.contactForm-sec .contactLeft .contact-form {
  margin-top: 4rem;
}

.contactForm-sec .contactRight .contactInfo .infoDiv {
  display: flex;
  color: #000;
  align-items: center;
}

.contactForm-sec .contactRight .contactInfo .infoDiv p {
  margin: 0 0 0 2.5rem;
}

.contactForm-sec .contactRight .contactInfo .socialMedia {
  display: flex;
  align-items: center;
  margin-top: 3rem;
}

.contactForm-sec .contactRight .contactInfo .socialMedia a {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2rem;
  box-shadow: 0px 8px 30px gainsboro;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  overflow: hidden;
}

.contactForm-sec .contactRight .contactInfo .socialMedia a:nth-child(1) {
  margin-left: 0;
}

.contact-form .inputBox input {
  height: 60px;
  box-shadow: 0px 10px 30px #d2d2d254;
  padding-right: 60px;
}

.form-control:focus {
  color: #0c4f98;
  background-color: #fff;
  border-color: #0c4f98;
  box-shadow: none;
}

.contact-form .inputBox .inputIcon {
  position: absolute;
  right: 16px;
  bottom: 17px;
}

.contact-form .inputBox .form-label {
  position: absolute;
  background: rgb(238, 246, 252);
  background: linear-gradient(
    180deg,
    rgba(238, 246, 252, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  left: 14px;
  top: -10px;
  padding: 0 5px;
  color: #747474;
}

.contact-form .inputContent {
  margin-bottom: 2rem;
}

.contact-form .inputBox textarea {
  height: 86px;
  padding-top: 26px;
  padding-right: 86px;
}

.contact-form .inputBox .sendBtn {
  border: none;
  background: none;
  border-radius: 100%;
  position: absolute;
  right: 16px;
  top: 12px;
}

.App {
  position: relative;
  min-height: 100vh;
}

/* .main-content-gap {
  padding-bottom: 7rem;
} */

.footer-sec {
  background: linear-gradient(45deg, #0a55a0, #1298e8, #0b57a0);
  padding: 3rem;
  /* position: absolute; 
  bottom: 0; */
  width: 100%;
  height: auto;
  z-index: 9;
}

.main-content-gap {
  margin: 5rem 0rem 0;
  /* min-height: 92vh; */
  min-height: calc(100vh - 200px);
  padding-bottom: 60px;
}

.footer-sec .socialLinks {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.footer-sec .socialLinks a {
  transition: all 0.6s;
  margin-right: 16px;
}

.footer-sec .socialLinks a img {
  width: 100%;
  max-width: 45px;
}

.footer-sec .socialLinks a:last-child {
  margin-right: 0;
}

.footer-sec .socialLinks a:hover {
  transform: translateY(-10px);
}

.footer-sec .copyRight {
  color: #fff;
  text-align: center;
  font-size: 0.9rem;
  padding: 0 12px;
}

.footer-sec .copyRight p {
  font-size: 0.9rem;
}

.footer-sec .copyRight a {
  font-size: 0.9rem;
  text-decoration: underline;
  color: rgb(255, 255, 255);
  padding: 0;
  font-weight: 500;
  font-style: italic;
}

.footer-sec .copyRight span {
  font-size: 0.8rem;
}

.form-group input {
  height: 60px;
  box-shadow: 0px 10px 30px #d2d2d254;
}

.active > .page-link,
.page-link.active {
  background-color: #0b4f9a !important;
  border-color: #0b4f9a !important;
}

/* Media Queries */
table thead th {
  white-space: nowrap;
}

.desk-none {
  display: none;
}

.dwn {
  background-color: #0585ce;
  padding: 0.2rem 0.7rem;
  font-size: 0.85rem !important;
  color: #fff;
  text-align: center;
  border-radius: 1rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
}

.breakText {
  white-space: pre-wrap;
  /* display: flex; */
  word-break: break-word;
  /* justify-content: center; */
}

.profileMargin {
  padding-top: 9rem;
  height: 100vh;
}

.head1 {
  margin-bottom: 9rem;
  margin-top: 0rem;
}
.subscriptions-all-btn-box > .button-wrapper > button {
  margin-left: 8px;
}

@media (max-width: 1920px) {
  .testimonials .bgPattern {
    right: -300px;
    max-width: 460px;
  }
}

/* --------------------------------------------------------[ 1600 PX ]--------- */

@media (max-width: 1600px) {
  .testimonials .bgPattern {
    right: -140px;
    max-width: 380px;
  }
}

/* --------------------------------------------------------[ 1440 PX ]--------- */

@media (max-width: 1440px) {
  .testimonials .bgPattern {
    right: -60px;
    max-width: 320px;
  }
}

/* --------------------------------------------------------[ 1366 PX ]--------- */

@media (max-width: 1366px) {
  .profileMargin {
    padding-bottom: 6rem;
    height: 100%;
  }

  .customDrop .dropdown-menu {
    left: -50px !important;
    width: 13%;
  }

  .dropdown-menu {
    left: -94px !important;
  }

  .testimonials .bgPattern {
    right: -115px;
    max-width: 300px;
  }

  .information-sec .infoCard {
    min-height: 292px;
  }

  .bannerLeft {
    padding: 0rem;
  }

  .banner-section .bannerTitle {
    padding-top: 3rem;
  }

  .bannerRight video {
    min-height: 67vh;
  }

  .information-sec {
    padding: 8.6rem 0 4rem 0;
  }
}

/* --------------------------------------------------------[ 1024 PX ]--------- */

@media (max-width: 1024px) {
  .subscription-box {
    max-width: 93% !important;
  }

  .profileMargin {
    padding-bottom: 6rem;
  }

  .information-sec {
    padding: 6.6rem 0 0 0;
    margin-bottom: 8rem;
  }

  .logoContent {
    max-width: 7.5rem;
  }

  button.btn.dropdown-toggle {
    font-size: 0.8rem;
  }

  .userTop {
    margin-right: 0px !important;
    margin-bottom: 0.5rem;
  }

  li.nav-item {
    margin: 0rem !important;
    white-space: nowrap;
  }

  #mynavbar .nav-link {
    font-size: 0.8rem;
    padding: 0.4rem;
  }

  button#dropdown-basic {
    font-size: 0.8rem;
    padding: 0.2rem;
  }

  .banner-section {
    margin-top: 4rem;
  }

  .banner-section .bannerTitle {
    padding-top: 2rem;
    padding-right: 2rem;
  }

  .banner-section .bannerHeading {
    font-size: 31px;
  }

  .testimonials .bgPattern {
    right: -43px;
    max-width: 270px;
    top: -112px;
  }

  .formLeft .formTitle h2 {
    font-size: 3rem;
  }

  .formLeft .imgBox img {
    right: 18px;
    width: 100%;
    max-width: 200px;
  }
}

/* --------------------------------------------------------[ 992 PX ]--------- */

@media (max-width: 820px) {
  .navbar-nav li.nav-item {
    margin: 0rem !important;
  }

  .subscription-box {
    max-width: 93% !important;
  }
}

/* --------------------------------------------------------[ 768 PX ]--------- */

@media (max-width: 768px) {
  .logoContent {
    max-width: 7.5rem;
  }

  .subscription-box {
    max-width: 583px !important;
    margin: 1rem 1.5rem !important;
  }

  .slick-prev {
    left: -37px !important;
  }

  .bannerLeft {
    margin-bottom: 2rem;
  }

  .topHeadingBack {
    font-size: 6rem;
  }

  .banner-section .bannerTitle {
    padding-right: 0rem;
  }

  .contact-section .contactForm-sec {
    padding: 2rem 2rem;
  }

  .contactForm-sec .contactRight > h3,
  .contactForm-sec .contactLeft > h3 {
    text-align: center;
  }

  .contactForm-sec .contactRight .contactInfo .socialMedia {
    justify-content: center;
  }

  .contactLeft {
    margin-top: 3rem;
  }

  .innerContact .sectionHead .gpsIcon {
    right: 95px;
  }

  .contact-section {
    padding-top: 2rem;
  }

  .testimonials .bgPattern {
    right: -35px;
    max-width: 190px;
    top: -70px;
  }

  .information-sec .infoCard {
    margin-bottom: 1rem;
    min-height: auto;
    box-shadow: 0px 0px 10px #dcdcdc66;
  }

  .profile {
    max-width: 100%;
    padding: 5px;
    text-align: center;
  }

  .center {
    max-height: 643px;
    overflow: hidden;
  }

  .linkVerifiication {
    flex-direction: column;
  }

  span.MuiCircularProgress-root.MuiCircularProgress-indeterminate.MuiCircularProgress-colorPrimary.css-18lrjg1-MuiCircularProgress-root {
    margin-top: 1rem;
  }
}

@media (max-width: 767px) {
  .slick-prev {
    left: -50px !important;
    z-index: 1;
  }

  .main-content-gap {
    margin: 8rem 0rem 0rem 0rem;
    padding-bottom: 9rem;
  }

  .formRight .formHead > h3 {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
  }

  .formRight {
    padding-left: 0rem;
  }

  .formTitle {
    /* display: none; */
    margin-top: 90px;
    margin-bottom: 3rem;
  }

  .formTitle span {
    font-size: 16px;
  }

  section.landing-section.sec .gstarted {
    margin-top: 3rem;
    justify-content: center;
    width: 100%;
  }

  .floatE {
    margin-top: 1rem;
  }

  .imgBox {
    /* display: none; */
  }

  .topLogo {
    padding-top: 0rem;
    margin-bottom: 0rem;
  }

  .formRight .formHead {
    margin-bottom: 4rem;
    margin-top: 2rem;
  }

  .cardHeading {
    padding: 1rem 0rem;
    min-height: unset !important;
  }

  .mb5 {
    margin-bottom: 9rem !important;
  }

  .subscription-box {
    width: 89%;
    margin: 2rem 1.5rem !important;
    max-width: 89% !important;
  }

  #mynavbar .navbar-nav {
    text-align: center;
  }

  #mynavbar li.nav-item {
    margin: 0;
    margin-bottom: 10px;
  }

  #mynavbar {
    background: #d2e7ff;
  }

  button.btn.dropdown-toggle {
    width: 100%;
  }

  .contactLeft {
    margin-top: 0rem;
  }

  .footer-sec {
    height: 9rem;
  }
}

/* --------------------------------------------------------[ 576 PX ]--------- */

@media (max-width: 576px) {
  .logoContent {
    max-width: 7.5rem;
  }

  .App {
    min-height: 100vh;
  }

  .subsc h6 {
    text-align: left;
    padding-left: 1rem;
  }

  .dwn {
    margin-left: 1rem;
  }
  .subscriptions-all-btn-box > .dwn {
    margin: 0;
  }
  .subscriptions-all-btn-box > a.unsubscribe-BTN {
    width: 100%;
    margin: auto;
  }
  .subscriptions-all-btn-box {
    padding-left: 16px;
    padding-right: 16px;
  }
  .subscriptions-all-btn-box > .button-wrapper {
    width: 100%;
  }
  .subscriptions-all-btn-box > .button-wrapper > button {
    width: 100%;
    margin-left: 0;
  }

  .head1 {
    margin-bottom: 2rem;
    margin-top: -2rem;
  }

  .powerd iframe {
    height: 130px !important;
  }

  .paypal-powered-by {
    height: 30px !important;
  }

  .subscriptionWrapper > div {
    padding: 0.5rem 0rem !important;
    margin-bottom: 0rem !important;
  }

  .subscriptionWrapper.mb-5 {
    text-align: center;
    margin-top: -4rem;
  }

  .dropdown-menu {
    left: 0px !important;
  }

  .profileHead {
    padding-bottom: 6rem !important;
  }

  .contact-form {
    margin-top: 2.5rem !important;
  }

  .mobile-none {
    display: none !important;
  }

  .contactInfo {
    margin-top: 2rem !important;
  }

  .contact-section .innerContact {
    min-height: 250px;
  }

  .contactInfo .mb-4 {
    margin-bottom: 0.5rem !important;
  }

  .contactInfo .mt-4 {
    margin-top: 1rem !important;
  }

  .contactForm-sec .contactRight .contactInfo .infoDiv p {
    margin: 0 0 0 1.5rem;
    font-size: 0.9rem;
  }

  .locationico {
    height: 40px;
  }

  .emailico {
    height: 18px;
  }

  .phoneico {
    width: 18px;
  }

  .socialMedia {
    display: none !important;
  }

  .gpsIcon {
    display: none;
  }

  .registration .formLeft {
    margin-top: 0rem;
  }

  .formRight .formHead > h3 {
    font-size: 2rem;
    margin-bottom: 0rem;
  }

  .formRight .formHead > p {
    font-size: 0.95rem;
  }

  .formRight .formHead {
    margin-bottom: 2rem;
    margin-top: 1rem;
  }

  .formRight {
    margin-bottom: 1rem;
  }

  .inputContent.mb-3 {
    margin-bottom: 0rem !important;
  }

  .desk-none {
    display: block;
    font-size: 0.9rem;
    margin-top: 0.5rem !important;
  }

  .formSection .btnCommon {
    margin-top: 1rem;
  }

  .cstm-dim .modal-content {
    width: 96% !important;
  }

  .form-section {
    width: 100%;
    max-width: 550px !important;
    padding: 1.5rem !important;
  }

  .detailsUser .modal-size {
    width: 100%;
    max-width: 320px !important;
  }

  .updateBtn {
    margin-top: 0rem !important;
  }

  .mb5 {
    margin-bottom: 9rem !important;
  }

  .subscription-box {
    width: 89%;
    margin: 1.2rem !important;
    max-width: 89% !important;
  }

  .topHeading.topHeading-live {
    margin-bottom: 1rem;
  }

  .main-content-gap {
    margin: 8rem 0rem 0rem 0rem;
    padding-bottom: 9rem;
  }

  .main-content-gap .topHeading {
    font-size: 2rem;
  }

  .slick-prev {
    left: -13px !important;
    z-index: 1;
  }

  .slick-next {
    right: 13px !important;
  }

  .testimonial-sec:after {
    z-index: -1;
  }

  .img-gif {
    width: 100% !important;
    height: 100% !important;
  }

  .topHeadingBack {
    font-size: 4rem;
    top: 15px;
    opacity: 0.2;
  }

  .topHeading {
    margin-top: 0.5rem;
  }

  .testimonials .bgPattern {
    right: -25px;
    max-width: 190px;
    top: -45px;
  }

  .banner-section .bannerTitle {
    padding-top: 4rem;
  }

  .information-sec .infoCard {
    box-shadow: 0px 0px 10px #dcdcdc66;
  }

  .innerContact .sectionHead .gpsIcon {
    right: 39px;
    max-width: 25px;
  }

  .footer-sec {
    height: auto;
    z-index: 999;
  }

  .footer-sec .copyRight p {
    font-size: 0.8rem;
  }

  .footer-sec .copyRight a {
    font-size: 0.8rem;
  }

  .footer-sec .copyRight span {
    font-size: 0.7rem;
  }

  .innerWrapper {
    padding-bottom: 9rem;
  }

  .profileMargin {
    height: 100%;
  }
}

@media (max-width: 414px) {
  .form-section {
    width: 100%;
    max-width: 380px !important;
    padding: 1.5rem !important;
  }

  .pagination {
    flex-wrap: wrap;
  }
}

@media (max-width: 375px) {
  .extmrg {
    padding-bottom: 14rem !important;
  }

  .profileHead {
    padding-bottom: 4rem !important;
  }

  .form-section {
    width: 100%;
    max-width: 360px !important;
    padding: 1.5rem !important;
  }

  .information-sec {
    margin-bottom: 8rem;
  }

  .testimonials {
    width: 360px !important;
  }

  .topHeadingBack {
    font-size: 3rem;
  }

  .innerWrapper {
    padding-bottom: 9rem;
  }
}

@media (max-width: 360px) {
  .profileHead {
    padding-bottom: 5rem !important;
  }

  .form-section {
    width: 100%;
    max-width: 340px !important;
    padding: 1.5rem !important;
  }
}

@media (max-width: 328px) {
  .form-section {
    width: 100%;
    max-width: 310px !important;
    padding: 1.5rem !important;
  }

  .innerWrapper {
    padding-bottom: 10rem;
  }
}

.text-underline {
  content: "";
  /* width: 100%; */
  display: block;
  margin-top: 4px;
  border-bottom: 2px solid #0b4f9a;
}

.ml-logo {
  height: 25px;
  width: 25px;
}

.icon-h {
  margin-left: 1rem;
}

.testi {
  background-color: #0585ce;
  color: #ffffff;
}

.testi-body {
  background-color: #ffffff;
}

.res-Head {
  background-color: #0585ce;
  color: #ffffff;
  text-align: center;
}

.color-theme {
  background-color: #0585ce;
  color: #ffffff;
}

/* .testim-left{
background-color: ;
} */

.testimonial-sec {
  /* background: linear-gradient(180deg, #aed6fd 65%, rgb(255 255 255) 50%); */
  /* background: #f5fbff; */
}

.form-control.border-dark {
  background: none;
  border: 0;
}

.form-control.border-dark {
  background: #fff;
  border: 1px solid #ebebeb !important;
  padding: 0;
  /* box-shadow: 0px 5px 7px 2px #00000012; */
  border-radius: 5px;
  height: 654px;
  overflow: auto;
  margin-bottom: 15px;
}

.form-control.border-dark1 {
  border: 0;
}

.form-control.border-dark.testi h3 {
  background: linear-gradient(180deg, #005eb5, #1391ce);
  padding: 11px 15px;
  border-radius: 5px 5px 0px 0px;
  border: 0;
  height: 100px;
  font-size: 17px;
}

.testimonial-sec:before {
  content: "";
  background: none;
  width: 266px;
  height: 266px;
  position: absolute;
  top: -119px;
  border-radius: 50%;
  border: 36px solid #d8ecfe;
  right: 304px;
}

.testimonial-sec:after {
  content: "";
  background: none;
  width: 232px;
  height: 232px;
  position: absolute;
  top: 34%;
  border-radius: 50%;
  border: 36px solid #d8ecfe;
  left: -154px;
  z-index: -1;
}

.testimonials .bgPattern {
  right: -487px;
  max-width: 488px;
  top: -525px;
  width: 250px;
}

.testimonial-sec .bgPattern {
  right: 1px;
  max-width: 488px;
  top: 496px;
  width: 71px;
}

.testimonial-sec img {
  margin: 0 auto;
}

img.bgPattern3 {
  left: 233px;
  top: 68px;
  transform: rotate(90deg);
}

/* .carousel-control-prev-icon {
    background-image:
} */

.my-datatable .btn:hover {
  border: 1px solid #005eb5;
}

.ml-2 {
  margin-left: 10rem;
  padding-left: 10rem;
}

.mt-2 {
  margin-top: 5rem;
  padding-top: 6rem;
}

.mt-3 {
  /* margin-top: 5rem; */
  padding-top: 9rem;
}

.mt-5 {
  margin-top: 5rem;
  /* padding-top: 9rem; */
}

.txt-wi {
  font-size: 15px;
  width: 160px;
  padding: 4px !important;
  border-radius: 4px;
  border: 1px solid gray;
}

input#email {
  padding: 4px !important;
  border-radius: 4px;
  border: 1px solid gray;
}

.pagin {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}

.NotiHed {
  borderradius: 0.25em;
  textalign: "center";
  color: rgb(19, 81, 196);
  border: "1px solid purple";
  padding: "0.5em";
  margin: 0 0 2rem 1rem;
}

.notification-table {
  margin-top: 30px;
}

.notification-table table tr td:nth-child(2) {
  width: 140px;
}

.box-showdow {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.-modBtn {
  padding-left: 5rem;
  margin-left: 2rem;
}

.carousel-indicators [data-bs-target] {
  background-color: #000 !important;
}

.carousel-control-prev {
  background-repeat: no-repeat;
  position: absolute;
  left: -1%;
  width: 30px;
}

.carousel-control-next {
  background-repeat: no-repeat;
  position: absolute;
  right: -1%;
  width: 30px;
}

.form-control.border-dark1 {
  padding: 15px;
  overflow: auto;
}

.container-fluid.bg-main-color form {
  padding: 13rem 25rem 6rem 25rem;
}

.bg-main-color {
  background-color: #aed6fd;
}

@media (max-width: 1200px) {
  .container-fluid.bg-main-color form {
    padding: 10rem 10rem 3rem 10rem;
  }
}

@media (max-width: 992px) {
  #mynavbar .nav-link {
    font-size: 12px;
  }
}

@media (max-width: 786px) {
  .container-fluid.bg-main-color form {
    padding: 10rem 5rem 3rem 5rem;
  }
}
@media (max-width: 764px) {
  .subscriptions-all-btn-box > .button-wrapper {
    width: 100%;
  }
  .subscriptions-all-btn-box > .button-wrapper > button {
    width: 100%;
    margin-left: 0;
  }
  .dwn {
    margin-right: 0;
  }
}

@media (max-width: 496px) {
  .subscription-box {
    padding: 1rem !important;
  }

  .t-header_currency {
    font-size: 26px !important;
    line-height: 64px;
  }

  .container-fluid.bg-main-color form {
    padding: 10rem 1rem 3rem 1rem;
  }
}

@media (max-width: 1450px) {
  #mynavbar li.nav-item {
    margin: 0 1rem;
  }
}

@media (max-width: 1200px) {
  #mynavbar li.nav-item {
    margin: 0 0.5rem;
  }
}

.color-theme1 {
  background-color: #0585ce;
  color: #ffffff;
  border: navajowhite;
  padding: 10px;
  border-radius: 5px;
}

.head-line::after {
  content: "";
  display: block;
  width: 26rem;
  padding-top: 3px;
  border-bottom: 2px solid #000000;
}

.testimonials {
  padding-bottom: 5rem;
}

@media (width <=1180px) {
  .contact-section .innerContact .sectionHead .gpsIcon {
    bottom: -44px;
    right: 40px;
  }
}

.slick-list {
  margin: 0 -10px;
}

.slick-slide > div {
  padding: 0 10px;
}

.slick-next:before,
.slick-prev:before {
  font-size: 34px;
  color: #0585ce;
}

.slick-prev {
  left: -50px;
}

.slick-next {
  right: -50px;
}

.slick-dots li button {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background: #cdcdcd;
  margin: 5px;
}

.slick-dots li button:before {
  color: #0000;
}

.slick-dots li.slick-active button {
  background: #0b4f9a;
  box-shadow: 0px 0px 20px #0b4f9a;
}

.slick-dots li.slick-active button:before {
  color: #0000;
}

.preview-image {
  display: inline-block;
}

.preview-image img {
  width: 100%;
}

.delete-user .modal-dialog {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.cstm-dim .modal-dialog {
  transform: none;
  width: 100%;
  max-width: 900px;
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.detailsUser .modal-dialog {
  transform: none;
  width: 100%;
  max-width: 550px;
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.detailsUser .modal-size {
  width: 100%;
  max-width: 550px;
  border: 0px;
  border-radius: 0.5rem;
}

.close-btn {
  background: #f9b6b6 !important;
  font-weight: 600 !important;
  color: #af0000 !important;
  margin: 0rem 0.3rem !important;
  border: 0px;
}

.btn-inactive {
  height: 40px;
  width: 80px;
  background-color: #f9b6b6;
  color: #000;
}

.btn-active {
  height: 40px;
  width: 80px;
  background-color: #a2f1d0;
  color: #000;
}

.btn-Add {
  font-size: x-large !important;
}

/* eslint-disable-next-line */
.mrt-3 {
  margin-top: 1rem;
}

.save-btn {
  background: #a2f1d0 !important;
  font-weight: 600 !important;
  color: #3e3e3e !important;
  margin: 0rem 0.3rem !important;
  border: 0px;
}

.ml-3 {
  margin-right: 2rem;
}

.img-response {
  width: 100% !important;
  max-width: 100px;
  margin-left: 0.5rem;
}

.img-gif {
  height: 850px;
  width: 1400px;
}

.img-Testi {
  width: 35px;
}

.img-prev {
  max-width: 100%;
  height: 50px !important;
  width: 50px !important;
}

/* ======01 Fab====== */
.minHeight {
  min-height: calc(100vh - 497px);
}

.updateBtn {
  background: linear-gradient(to top, #0b4f9a, #0585ce);
  color: #fff;
  border: 0;
  border-radius: 30px;
  padding: 12px 3rem;
  margin-top: 2rem;
  box-shadow: 0px 6px 25px #0b4f9a40;
  transition: all 0.5s;
}

.updateBtn:hover {
  color: #fff;
}

.award-sec {
  padding-top: 9rem;
  padding-bottom: 2rem;
}

.award-left,
.award-right {
  background: #fff;
  border: 1px solid gainsboro;
  border-radius: 8px;
  box-shadow: 0px 5px 20px gainsboro;
  padding: 2rem 0px 0px 0px;
  min-height: 100%;
  word-break: break-all;
  padding: 8px 20px;
}

.cardHeading {
  min-height: 130px;
}

.imgLink {
  display: inline-block;
}

.imgLink img {
  width: 100%;
  max-width: 240px;
}

.form-section {
  width: 100%;
  max-width: 700px;
  margin: auto;
  padding: 3rem;
  background: #1085d312;
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.subscription-title {
  color: #0b4e9a;
  font-size: 2rem;
  margin: 0px;
}

.sub-title {
  font-size: 1.1rem;
  color: #0e77b3;
}

.yearly-price {
  display: flex;
  align-items: flex-end;
  line-height: 50px;
  justify-content: center;
  margin: 3rem 0rem;
}

.t-header_currency {
  font-size: 40px;
  line-height: 64px;
  font-weight: 600;
  color: #272828;
}

span.price_currency,
.t-price_suffix {
  font-size: 1.1rem;
  color: #323232b3;
  line-height: 58px;
  margin-right: 0.2rem;
}

.subscription-box {
  text-align: center;
  width: 100%;
  max-width: 500px;
  margin: 0rem 1.5rem;
  padding: 3rem;
  background: #1085d312;
  border-radius: 1rem;
  box-shadow: rgb(0 0 0 / 2%) 0px 1px 3px 0px,
    rgb(27 31 35 / 15%) 0px 0px 0px 1px;
  position: relative;
}

.subscribeBtn {
  background: #0b4e9a;
  color: #fff;
  padding: 0.7rem 2rem;
  border: 0px;
  border-radius: 0.2rem;
  font-size: 1.1rem;
  width: 85%;
}

.border-doted {
  border-top: 3px dotted black;
  padding: 20px;
  /* border-radius: 50%; */
}

.PP-btn {
  shape-rendering: "rect";
  color: #0585ce;
  -ms-layout-grid-line: "horizontal";
  label: "subscribe";
}

.testimonial-sec .sectionHead {
  margin-bottom: 0;
}

.subscriptionWrapper > div {
  box-shadow: 3px 0px 20px gainsboro;
  padding: 2rem;
  border-radius: 14px;
  background: #fff;
  width: 100%;
  max-width: 1057px;
  margin: auto;
}

.subscriptionWrapper > div h1 {
  color: #0b4f9a;
}

.subscriptionWrapper > div h6.name {
  font-size: 20px;
}

.subscriptionWrapper > div a.d-block {
  font-size: 22px;
}

.expireMsg {
  color: #af0000;
}

/* .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiTableContainer-root.box-showdow.word-break.css-11xur9t-MuiPaper-root-MuiTableContainer-root table tbody tr td {
  word-break: break-all;
} */
.word-break {
  word-break: break-all !important;
}

.award-dropdown .dropdown-menu.show {
  padding: 0;
}

.sectionHead.sectionHead-live {
  margin-bottom: -2rem;
}

.topHeading.topHeading-live {
  margin-bottom: 1rem;
}

.userTop {
  margin-right: 30px;
}

li.nav-item {
  margin: 0rem 0.9rem !important;
  white-space: nowrap;
}

/* .main-header-section li.nav-item {
  margin: 0 0.9rem !important;
} */
/* .main-header-admin-section li.nav-item{
  margin: 0 1rem !important;
} */

/* .Testimonial_Card .item {
  margin-bottom: 2rem;
  position: relative;
} */
.Testimonial_Card .item {
  margin-bottom: 2rem;
  position: relative;
  background: #fff;
  padding: 0.5rem;
  border-radius: 0.5rem;
  width: 350px;
  margin: auto;
}

.Testimonial_Card .item:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.Testimonial_Card .item .hideBtn {
  position: absolute;
  top: 13px;
  right: 10px;
}

.hideBtn button {
  padding: 0;
  margin: 5px;
}

.Testimonial_Card .item img {
  width: 100%;
  padding: 0.5rem;
}

.hideBtn {
  display: none;
}

.Testimonial_Card .item:hover > .hideBtn {
  display: block;
}

.btn-Add {
  background: #0c5da7;
  width: 40px !important;
  height: 35px !important;
  color: #fff;
  border-radius: 0.25rem;
}

.linkVerifiication {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.readmax {
  font-size: 2.5rem;
  font-weight: 600;
  color: #424242;
  line-height: 2.2rem;
  margin-top: 2rem;
  display: block;
}

.blockText {
  display: block;
  margin-bottom: 3.5rem;
}

/*UI CSS 21 June 2023*/

/* 
@media (max-width: 820px){
  .slick-prev {
      left: -40px;
  }
  .slick-next {
      right: -20px ;
  }
  }

  @media (max-width: 768px){
    .slick-prev {
        left: -35px;
    }
    .slick-next {
        right: 0px ;
    }
    
    }
  

@media (max-width: 380px){
  .footer-sec{
  position:relative;}
  .App{
    min-height: auto;
  }
  
  }

  @media (max-width: 480px){
    .slick-prev {
        left: -10px !important;
    }
    .slick-next {
        right: 10px !important;
    }
    }  */

.react-tel-input .form-control {
  width: 100% !important;
}

/*-------------------------------------------------------------------------------------------Landing Page---------------------------------------------------*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

body {
  color: #444444;
  background-color: #fff;
  margin: 0px;
  padding: 0px;
}

* {
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.06rem;
}

a {
  color: #4154f1;
  text-decoration: none;
}

a:hover {
  color: #717ff5;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 {
  color: #444444;
}

/*--------------------------------------------------------------
# Sections
--------------------------------------------------------------*/

section {
  padding: 40px 0;
  overflow: hidden;
}

.flair-img {
  position: absolute;
  left: -115px;
  bottom: -102px;
}

.flair-img img {
  width: 22rem;
  opacity: 0.3;
}

.transaction-bg {
  position: absolute;
  right: 17px;
  top: 232px;
  z-index: 1;
}

.tcenter {
  text-align: center;
  margin-bottom: 7rem;
}

/* .mbs {
  margin-bottom: 12rem;
} */

/* 
img {
  width: 100%;
} */

.custom-container p {
  font-size: 1.7rem;
}

.mrl {
  margin-left: 2rem;
}

.mrr {
  margin-right: 2rem;
}

.mrb {
  margin-bottom: 6rem;
  margin-top: 2rem;
}

.bord-pad {
  padding: 1.5rem;
  background-color: #f8f7f7;
}

.notop-mar {
  margin-top: 0 !important;
}

/*--------------------------------------------------------------
# finance Section
--------------------------------------------------------------*/

.landing-section {
  margin: 0rem;
  height: 100%;
  background: rgb(133 131 246 / 9%);
  display: flex;
  align-items: center;
  position: relative;
  padding: 0rem 0rem;
}

/* .bgrow {
  border-top: 1px solid #cdcdcd;
  margin-bottom: 3rem;
} */

.landing-section h1 {
  font-size: 4rem;
  font-weight: bold;
  line-height: 5.5rem;
  color: #000;
  margin-bottom: 1.5rem;
  margin-top: 7rem;
}

.custom-row {
  display: flex;
  justify-content: space-between;
}

.column-8 {
  width: 75%;
}

.column-4 {
  width: 25%;
}

.column-7 {
  width: 60%;
}

.column-5 {
  width: 40%;
}

.column-6 {
  width: 50%;
}

.data-guide {
  margin-top: 3rem;
}

.floatE {
  width: 400px;
  display: flex;
  margin: 4rem auto;
}

.gstarted {
  display: flex;
  align-items: center;
  /* margin-top: 3rem; */
}

section.landing-section.sec .gstarted {
  margin-top: 3rem;
}

.get-str {
  background: #125597;
  margin-right: 2rem;
}

a.get-str {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 150px;
  border-radius: 35px;
}

.get-strs {
  background: #fff;
  border: 1px solid #d5d5d5;
}

a.get-strs {
  color: #1d1d1d;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 68px;
  width: 224px;
  border-radius: 35px;
}

.get-head {
  font-size: 1.5rem;
}

.column-6 img {
  background: #fff;
  padding: 1rem;
  border-radius: 0.3rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 92%;
}

.smalltext {
  font-size: 1.2rem !important;
}

.banner-logo img {
  width: 100%;
  max-width: 170px;
  position: absolute;
  top: -65px;
  left: 0;
}

.back-btn {
  margin-top: 15px !important;
  background-color: #005eb5;
  border-radius: 50%;
  padding-top: 0px;
  height: 50px;
  width: 50px;
}

button.btn.add-member {
  background: #0584ce;
  color: white;
}

.confirm-btns {
  background: #0584ce !important;
  color: white !important;
  margin-right: 15px !important;
  font-weight: 500 !important;
}

.cancle-buttons {
  background: rgb(78, 17, 17) !important;
  color: white !important;
  font-weight: 500 !important;
  font-size: 15px !important;
}

.add-member-class {
  text-align: right;
}

span.t-header_currency {
  font-size: 20px;
}

.remove-btns {
  text-align: -webkit-right !important;
}

.footer-sec {
  background: linear-gradient(45deg, #0a55a0, #1298e8, #0b57a0);
  padding: 3rem;
  width: 100% !important;
  min-height: 160px;
  height: auto;
  /* position: fixed;
  z-index: 999;
  bottom: 0px; */
  position: absolute;
  bottom: 0;
}

.label-names label {
  position: absolute;
  top: -14px;
  left: 8px;
  background: white;
  padding: 0px 7px;
}

.mb-4.new-class.row {
  margin-top: 26px;
}

.main-input-class {
  position: relative;
}

/* 28-02-2024 new css  */

.card-main {
  background: #ffffff;
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  margin-bottom: 26px;
}

.card-head {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px 12px;
  background: linear-gradient(45deg, #0a55a0, #1298e8, #0b57a0);
}

.head-card-img svg {
  width: 24px;
  height: 24px;
  fill: #fff;
}

.head-card-text {
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  margin: 0;
}

.card-inner-p {
  padding: 24px 30px 26px;
  border-bottom: 2px solid #f7f7f7;
}

.body-card .card-inner-p:last-child {
  border-bottom: 0;
}

.card-body-head {
  color: #0453a0;
  font-size: 18px;
  margin: 0;
}

.formSection.form-custm-group .inputBox .form-label {
  font-size: 14px;
}

.formSection.form-custm-group .inputBox input {
  height: 44px;
  box-shadow: unset;
}

.cb-head-icon {
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.cb-head-icon svg {
  width: 40px;
  height: 40px;
  color: #1294e3;
}

.form-select.custmSelect {
  height: 44px;
}

.form-select.custmSelect:focus {
  color: #0c4f98;
  background-color: #fff;
  border-color: #0c4f98;
  box-shadow: none;
}

.custm-border {
  box-shadow: 0 0 1px 1px rgb(16 129 207);
}

/* responsive code below start */

@media screen and (min-width: 768px) and (max-width: 2500px) {
  .custom-container {
    width: 100%;
    max-width: 1400px;
    margin: auto;
    position: relative;
  }
}

@media screen and (min-width: 1280px) {
  .sec {
    min-height: 100vh;
  }
}

@media screen and (max-width: 1280px) {
  .floatE {
    width: 100%;
    max-width: 300px;
  }

  .landing-section h1 {
    font-size: 3.5rem;
    line-height: 4.5rem;
  }

  .landing-section .custom-row {
    padding: 0rem 3rem;
  }

  .banner-logo img {
    width: 100%;
    max-width: 199px;
    position: absolute;
    top: -71px;
    left: 46px;
  }
}

@media screen and (max-width: 1024px) {
  .custom-row {
    padding: 0rem 2rem;
  }
}

@media screen and (max-width: 960px) {
  .banner-logo img {
    width: 100%;
    max-width: 150px;
    position: absolute;
    top: -80px;
    left: 80px;
  }

  .column-6 {
    width: 100%;
    text-align: center;
  }

  .custom-row {
    flex-direction: column;
    /* margin: 0rem 1rem 3rem 2rem; */
  }

  .transaction-bg {
    display: none;
  }

  .mrr {
    margin: unset;
  }

  .mrl {
    margin: unset;
  }

  .custom-row:nth-child(2) {
    flex-direction: column-reverse;
  }

  .custom-row:nth-child(4) {
    flex-direction: column-reverse;
  }

  .custom-row:nth-child(7) {
    flex-direction: column-reverse;
  }

  .custom-container p {
    font-size: 1.2rem;
  }

  .landing-section .column-5 {
    display: none;
  }

  .column-7 {
    width: 100%;
  }

  .flair-img {
    position: absolute;
    left: -115px;
    bottom: -102px;
    z-index: -1;
  }
}

@media screen and (max-width: 768px) {
  .banner-logo img {
    width: 100%;
    max-width: 150px;
    position: absolute;
    top: 6px;
    left: 60px;
  }

  .landing-section h1 {
    font-size: 2.5rem;
    line-height: 3.5rem;
    margin-top: 8rem;
  }

  .landing-section {
    height: 100%;
    padding: 0rem;
  }

  .custom-row {
    flex-direction: column;
    padding: 0.5rem 0rem;
    margin: 0rem 1rem 3rem 1rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .landing-section .custom-row {
    box-shadow: none;
  }

  .column-6 img {
    box-shadow: none;
    width: 90%;
  }

  .custom-container p {
    font-size: 1.2rem;
    padding: 0.5rem;
  }
}

@media screen and (max-width: 576px) {
  .banner-logo img {
    width: 100%;
    max-width: 150px;
    position: absolute;
    top: 5px;
    left: 15px;
  }

  .landing-section {
    height: 100%;
  }

  .landing-section .custom-row {
    padding: 3rem 0rem 0 0;
  }

  .custom-container p {
    font-size: 0.85rem;
    padding: 0.5rem;
  }

  .smalltext {
    font-size: 0.85rem !important;
  }

  .tcenter {
    padding: 0rem 0.5rem;
    margin-bottom: 7rem;
  }

  .get-head {
    font-size: 0.76rem;
  }

  .flair-img {
    display: none;
  }

  .transaction-bg {
    display: none;
  }

  .floatE {
    /* display: none; */
  }

  .landing-section h1 {
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin-top: 8rem;
  }

  a.get-str {
    height: 50px;
    width: 100%;
    max-width: 224px;
  }

  a.get-strs {
    height: 50px;
    width: 100%;
    max-width: 224px;
  }

  .data-guide {
    margin-top: 0rem;
  }

  .text-center {
    text-align: center;
  }

  .custom-row {
    flex-direction: column;
    padding: 0.5rem 0rem;
    margin: 0rem 1rem 3rem 1rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .landing-section .custom-row {
    box-shadow: none;
  }

  .column-6 img {
    box-shadow: none;
    width: 90%;
  }
}

.price-info-table {
  font-family: Roboto, Helvetica, Arial, sans-serif !important;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 1.5rem !important;
  letter-spacing: 0.01071em !important;
  vertical-align: inherit !important;
  color: black;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.price-info {
  font-family: Roboto, Helvetica, Arial, sans-serif !important;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 1.5rem !important;
  letter-spacing: 0.01071em !important;
  vertical-align: inherit !important;
  color: white;
}

.child-search {
  outline: none;
  border: 1px solid grey;
  border-radius: 4px;
  height: 100% !important;
  padding: 5px 14px;
}

@media screen and (max-width: 414px) {
  .landing-section {
    height: 100%;
  }
}

@media screen and (max-width: 374px) {
  .landing-section {
    height: 100%;
  }

  .gstarted {
    flex-direction: column;
  }

  a.get-str {
    height: 50px;
    width: 100% !important;
    max-width: 100%;
  }

  a.get-strs {
    height: 50px;
    width: 100% !important;
    max-width: 100%;
  }

  .get-str {
    margin-right: 0rem;
    margin-bottom: 0.5rem;
  }
}

/* Landscape */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) {
  .landing-section .custom-row {
    padding: 0rem 3rem;
  }

  .banner-logo img {
    width: 100%;
    max-width: 170px;
    position: absolute;
    top: -65px;
    left: 51px;
  }

  .custom-row {
    padding: 0rem 2rem;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .banner-logo img {
    width: 100%;
    max-width: 199px;
    position: absolute;
    top: -72px;
    left: 46px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .formTitle {
    margin-bottom: 1rem;
    padding-top: 4rem;
  }

  section.landing-section.sec .gstarted {
    justify-content: center;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  img.floatE {
    margin-top: 5rem;
  }
}

/* .css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input {
  padding:0 !important
} */

.tbl_cstm-manage-grp {
  margin-top: 1rem;
  border-radius: 1rem;
  text-align: center;
}

.tbl_cstm-manage-grp table tr th:last-child {
  /* width: 130px; */
  /* text-align: center !important;
  justify-content: center !important; */
}

.tbl_cstm-manage-grp table thead {
  background: #0e70bb;
  color: white;
}

.tbl_cstm-manage-grp table thead th {
  font-weight: 600;
}

span.warning-icon {
  font-size: 18px;
  color: red;
  padding-right: 10px;
}

.model-body-inner-sec {
  border-left: 4px solid #f76060;
  background: #fff5f5;
  padding: 18px;
  border-radius: 4px;
}

span.content-text {
  font-size: 17px;
}

.model-head-new {
  border-bottom: 0px;
}

.model-footer-new {
  border-top: 0px;
}
.subscription-box.active {
  background-image: linear-gradient(45deg, #0a55a0, #1298e8, #0b57a0);
  position: relative;
}
.subscription-box.active h2.subscription-title {
  color: #fff;
}
.subscription-box.active .sub-title {
  color: #ddd;
}
.subscription-box.active .view {
  color: #08ff98;
}
.subscription-box.active p {
  color: #fff;
}
.subscription-box.active .yearly-price .t-header_currency {
  color: #fff;
}
.subscription-box.active .yearly-price .t-price_suffix {
  color: #ddd;
}
.subscription-box .Plan-status {
  min-height: 40px;
}
.subscription-box button.plan {
  padding: 5px 15px;
  font-weight: 600;
  border: none;
  box-shadow: 0 0 8px #000;
  border-radius: 10px 0px;
  background-image: linear-gradient(45deg, #0dcaf0, #4affde, #08ff98);
  position: absolute;
  top: 10px;
  left: 10px;
}

.subscription-box p.updateqtY {
  white-space: nowrap;
}
.paid {
  /* color: #08ff98; */
  color: green;
}
.unpaid {
  color: #d40b0b;
}

.min-height-637 {
  min-height: 637px;
}

.mtcstm-1 {
  margin-top: 2rem !important;
}
.upgrade-plan-modal-body {
  width: 100%;
  overflow: auto;
}
/* .App > div {
  padding-bottom: 0 !important;
} */
/* .App > div > div {
  padding-bottom: 0 !important;
  position: static !important;
} */
/* .App > div.Toastify {
  padding-bottom: unset !important;
} */
.css-1w1rijm-MuiButtonBase-root-MuiButton-root {
  border: 1px solid #0584ce !important;
  background: transparent !important;
  gap: 10px;
}
.pointer {
  cursor: pointer;
}
.rec-radio label {
  margin-right: 5px;
}
.rec-radio button {
  background: transparent;
  border: 1px solid #0584ce;
  padding-right: 25px;
  color: #0584ce;
  /* border-radius: 10%; */
  /* margin-right: 10px;*/
  margin-bottom: 20px;
}
.rec-radio button:hover {
  background: #0584ce !important;
  color: #fff;
  border: 1px solid #0584ce;
}
.rec-radio .active {
  background: #0584ce !important;
  color: #fff !important;
}

.rec-radio [type="radio"] {
  float: right;
  visibility: hidden;
}

ul {
  margin: 0;
}
@media only screen and (min-width: 1025px) {
  .formTitle {
    padding-top: 4rem;
  }
}
select.selectCstm {
  border: 0.5px solid #ddd;
  border-radius: 6px;
  color: #575757;
}
@media only screen and (max-width: 767px) {
  .tbl_cstm-manage-grp {
    width: 100%;
    overflow: auto;
  }
  .bannerRight {
    display: none;
  }
  h2.topHeading {
    padding: 0 25px;
  }
  .block-BTN {
    flex-direction: column;
    gap: 20px;
  }
  .block-BTN a.unsubscribe-BTN {
    width: 100%;
    margin: auto;
  }
}

div::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f5f5f5;
}

div::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

div::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 10px;
}
body::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

body::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 10px;
}

.subActive {
  background-color: #0b4f9a !important;
  color: #fff;
}

@media only screen and (max-width: 768px) {
  .cstm-award-drpdwn .dropdown-menu {
    width: unset !important;
  }
  .block-BTN {
    flex-wrap: wrap;
  }
}

.cstm-award-drpdwn .cstm-nav-link.dropdown-menu.show a:active,
.cstm-award-drpdwn .cstm-nav-link.dropdown-menu.show a:focus {
  background: unset;
  color: #000;
}
/* 18 Dec 2024 */
.min-height-dashboard .main-content-gap {
  min-height: calc(100dvh - 302px);
}
.base_modal_mui .modal-footer {
  padding: 12px 26px 12px 12px;
  border-top: 1px solid rgb(222, 226, 230);
  margin: 20px -30px -30px;
}
.base_modal_mui .modal-header {
  margin: -55px -32px 15px -32px;
  padding: 9px 32px;
  border-bottom: 1px solid #80808038;
}
p.cstm-ellipses-text {
  white-space: nowrap;
  max-width: 780px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  margin: 0;
}
button:disabled {
  background: #80808042 !important;
  color: #00000066 !important;
  border-color: #80808042 !important;
  cursor: not-allowed !important;
  pointer-events: auto !important;
}
.line-answe-two,
.content-ellipses-blog,
.title-ellipses-blog {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.line-questions-one {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.fixed-width-150 {
  width: 150px;
}
.blog-image img {
  max-width: 100%;
}
.heading_dashboard {
  padding-top: 30px;
  font-size: 2.5rem;
  font-weight: 600;
  color: #424242;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.SpeedContainer .MuiBox-root {
  height: unset;
}
.clear {
  clear: both;
}
